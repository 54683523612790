let _ = require('underscore');

let active = [
  {name: "archery", attribute: "agility", type: "combat"},
  {name: "automatics", attribute: "agility", group: "firearms", type: "combat"},
  {name: "longarms", attribute: "agility", group: "firearms", type: "combat"},
  {name: "pistols", attribute: "agility", group: "firearms", type: "combat"},
  {name: "blades", attribute: "agility", group: "close combat", type: "combat"},
  {name: "clubs", attribute: "agility", group: "close combat", type: "combat"},
  {name: "unarmed", attribute: "agility", group: "close combat", type: "combat"},
  {name: "exotic melee weapon", attribute: "agility", type: "combat", specific: true},
  {name: "exotic ranged weapon", attribute: "agility", type: "combat", specific: true},
  {name: "heavy weapons", attribute: "agility", type: "combat"},
  {name: "throwing weapons", attribute: "agility", type: "combat"},
  {name: "escape artist", attribute: "agility", type: "physical"},
  {name: "gymnastics", attribute: "agility", group: "athletics", type: "physical"},
  {name: "locksmith", attribute: "agility", type: "technical"},
  {name: "palming", attribute: "agility", group: "stealth", type: "physical"},
  {name: "sneaking", attribute: "agility", group: "stealth", type: "physical"},
  {name: "gunnery", attribute: "agility", type: "vehicle"},
  {name: "diving", attribute: "body", type: "physical"},
  {name: "free-fall", attribute: "body", type: "physical"},
  {name: "pilot aerospace", attribute: "reaction", type: "vehicle"},
  {name: "pilot aircraft", attribute: "reaction", type: "vehicle"},
  {name: "pilot exotic vehicle", attribute: "reaction", type: "vehicle", specific: true},
  {name: "pilot ground craft", attribute: "reaction", type: "vehicle"},
  {name: "pilot walker", attribute: "reaction", type: "vehicle"},
  {name: "pilot watercraft", attribute: "reaction", type: "vehicle"},
  {name: "running", attribute: "strength", group: "athletics", type: "physical"},
  {name: "swimming", attribute: "strength", group: "athletics", type: "physical"},
  {name: "con", attribute: "charisma", group: "acting", type: "social"},
  {name: "impersonation", attribute: "charisma", group: "acting", type: "social"},
  {name: "performance", attribute: "charisma", group: "acting", type: "social"},
  {name: "etiquette", attribute: "charisma", group: "influence", type: "social"},
  {name: "leadership", attribute: "charisma", group: "influence", type: "social"},
  {name: "negotiation", attribute: "charisma", group: "influence", type: "social"},
  {name: "instruction", attribute: "charisma", type: "social"},
  {name: "intimidation", attribute: "charisma", type: "social"},
  {name: "animal handling", attribute: "charisma", type: "technical"},
  {name: "artisan", attribute: "intuition", type: "technical"},
  {name: "assensing", attribute: "intuition", type: "magical"},
  {name: "disguise", attribute: "intuition", group: "stealth", type: "physical"},
  {name: "navigation", attribute: "intuition", group: "outdoors", type: "technical"},
  {name: "perception", attribute: "intuition", type: "physical"},
  {name: "tracking", attribute: "intuition", group: "outdoors", type: "technical"},
  {name: "aeronautics mechanic", attribute: "logic", group: "engineering", type: "technical"},
  {name: "automotive mechanic", attribute: "logic", group: "engineering", type: "technical"},
  {name: "industrial mechanic", attribute: "logic", group: "engineering", type: "technical"},
  {name: "nautical mechanic", attribute: "logic", group: "engineering", type: "technical"},
  {name: "arcana", attribute: "logic", type: "magical"},
  {name: "armorer", attribute: "logic", type: "technical"},
  {name: "biotechnology", attribute: "logic", group: "biotech", type: "technical"},
  {name: "chemistry", attribute: "logic", type: "technical"},
  {name: "computer", attribute: "logic", group: "electronics", type: "technical"},
  {name: "cybertechnology", attribute: "logic", group: "biotech", type: "technical"},
  {name: "cybercombat", attribute: "logic", group: "cracking", type: "technical"},
  {name: "demolitions", attribute: "logic", type: "technical"},
  {name: "electronic warfare", attribute: "logic", type: "technical"},
  {name: "first aid", attribute: "logic", group: "biotech", type: "technical"},
  {name: "hacking", attribute: "logic", group: "cracking", type: "technical"},
  {name: "hardware", attribute: "logic", group: "electronics", type: "technical"},
  {name: "medicine", attribute: "logic", group: "biotech", type: "technical"},
  {name: "software", attribute: "logic", group: "electronics", type: "technical"},
  {name: "forgery", attribute: "logic", group: "cracking", type: "technical"},
  {name: "astral combat", attribute: "willpower", type: "magical"},
  {name: "survival", attribute: "willpower", group: "outdoors", type: "physical"},
  {name: "alchemy", attribute: "magic", group: "enchanting", type: "magical"},
  {name: "artificing", attribute: "magic", group: "enchanting", type: "magical"},
  {name: "banishing", attribute: "magic", group: "conjuring", type: "magical"},
  {name: "binding", attribute: "magic", group: "conjuring", type: "magical"},
  {name: "counterspelling", attribute: "magic", group: "sorcery", type: "magical"},
  {name: "disenchanting", attribute: "magic", group: "enchanting", type: "magical"},
  {name: "ritual spellcasting", attribute: "magic", group: "sorcery", type: "magical"},
  {name: "spellcasting", attribute: "magic", group: "sorcery", type: "magical"},
  {name: "summoning", attribute: "magic", group: "conjuring", type: "magical"},
  {name: "compiling", attribute: "resonance", group: "tasking", type: "resonance"},
  {name: "decompiling", attribute: "resonance", group: "tasking", type: "resonance"},
  {name: "registering", attribute: "resonance", group: "tasking", type: "resonance"}
];
let knowledge = [
  {category: "academic", attribute: "logic"},
  {category: "interests", attribute: "intuition"},
  {category: "professional", attribute: "logic"},
  {category: "street", attribute: "intuition"}
];

let groups = [
  {name: 'close combat', type: 'combat'},
  {name: 'firearms', type: 'combat'},
  {name: 'athletics', type: 'physical'},
  {name: 'stealth', type: 'physical'},
  {name: 'acting', type: 'social'},
  {name: 'influence', type: 'social'},
  {name: 'biotech', type: 'technical'},
  {name: 'cracking', type: 'technical'},
  {name: 'electronics', type: 'technical'},
  {name: 'engineering', type: 'technical'},
  {name: 'outdoors', type: 'technical'},
  {name: 'conjuring', type: 'magical'},
  {name: 'enchanting', type: 'magical'},
  {name: 'sorcery', type: 'magical'},
  {name: 'tasking', type: 'resonance'}
];

module.exports = {active, knowledge, groups};